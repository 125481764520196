export const en: any = {

  home: {
    show_all_button: 'Show all',
  },

  footer: {
    contacts: 'Contacts',
  },

  tooltip: {
    choose_language: 'Choose a language',
    home: 'Go to home',
    logout: 'Logout',
    login: 'Login',
    admin: 'Admin section',
    menu: 'Toggle menu',
  },

  menu: {
    search: 'Search',
    search_placeholder: 'Find a map in the catalog',
    home: 'Home',
    maps: 'maps',
    info: 'info',
    login: 'Login',
    logout: 'Logout',
    admin: 'Admin',
    data: 'Data',
    group: 'Groups',
    organization: 'Organizations',
    toggle: 'MENU',
    catalog: 'Catalog',
    404: 'Page Not Found',
  },

  login: {
    username: 'Username',
    password: 'Password',
    submit: 'Login',
    title: 'Login to the admin system',
    reset_password_url: 'Forgot your password?',
    requiredField: 'This field is required',
    erroreLogin: 'Login error',
  },

  language: {
    it: 'Italiano',
    en: 'English',
  },

  maps: {
    services: 'Services',
    group: 'Group',
    edit: 'Edit',
    view: 'View',
  },

  readmore: 'Read more »',
  readless: '« Read less',

  ogc_services: 'Network services - OGC',

};

export default en;